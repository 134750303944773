import React from 'react';
import { HashRouter, Route, Switch} from 'react-router-dom';
import axios from 'axios'
import moment from 'moment'
import store from './store'
import AuthenticationService,{USER_NAME_SESSION_ATTRIBUTE_NAME,USER_NAME_SESSION_TOKEN} from './service/AuthenticationService'
import './scss/style.scss';

//const history = useHistory();
// let authToken = sessionStorage.getItem(USER_NAME_SESSION_TOKEN)
// if(authToken !== null && authToken !== undefined){
//   axios.defaults.headers.authorization = authToken
// }

axios.interceptors.request.use(
  (config) => {
      if (AuthenticationService.isUserLoggedIn()) {
          let authToken = sessionStorage.getItem(USER_NAME_SESSION_TOKEN)
          config.headers.authorization = authToken
      }
      if(config.params){
        config.params = Object.assign(config.params,{
          '_t': moment().unix()
        })
      }else{
        config.params = Object.assign({},{
          '_t': moment().unix()
        })
      }
      return config
  }
)

axios.interceptors.response.use(response => {
  return response
},error => {  
  console.log(error)
  if(error.response.config.url === "/api/chatlog/unread"){
    return Promise.reject(error)
  }
  else if(error.response.config.url === "/api/dispensingreservationdetail/unconfirmed"){
    return Promise.reject(error)
  }
  else if(error.response.config.url === "/api/dispensingreservationdetail/checkremind"){
    return Promise.reject(error)
  }
  else if(error.response.status === 403){
    if(error.response.config.url !== "/api/user/login"){
      sessionStorage.removeItem(USER_NAME_SESSION_ATTRIBUTE_NAME)
      window.location.href = "/#/login"
    }
  }else{
    store.dispatch({type: 'set', showLoading: false})
    store.dispatch({type: 'set', showApiError: true})
    store.dispatch({type: 'set', currentApiError: error})
  }
  return Promise.reject(error)
})

const loading = (
  <div className="pt-3 text-center">
    <div className="sk-spinner sk-spinner-pulse"></div>
  </div>
)

// Containers
const TheLayout = React.lazy(() => import('./containers/TheLayout'));

// Pages
const Login = React.lazy(() => import('./views/pages/login/Login'));
const PasswordExpired = React.lazy(() => import('./views/pages/login/PasswordExpired'));
const PasswordChange = React.lazy(() => import('./views/pages/login/PasswordChange'));
const Register = React.lazy(() => import('./views/pages/register/Register'));
const Page404 = React.lazy(() => import('./views/pages/page404/Page404'));
const Page500 = React.lazy(() => import('./views/pages/page500/Page500'));

const App = () => {

    return (
      <HashRouter>
          <React.Suspense fallback={loading}>
            <Switch>
              <Route exact path="/login" name="Login Page" render={props => <Login {...props}/>} />
              <Route exact path="/passwordexpired" name="PasswordExpired Page" render={props => <PasswordExpired {...props}/>} />
              <Route exact path="/passwordchange" name="PasswordChange Page" render={props => <PasswordChange {...props}/>} />
              <Route exact path="/register" name="Register Page" render={props => <Register {...props}/>} />
              <Route exact path="/404" name="Page 404" render={props => <Page404 {...props}/>} />
              <Route exact path="/500" name="Page 500" render={props => <Page500 {...props}/>} />
              <Route path="/" name="Home" render={props => <TheLayout {...props}/>} />
            </Switch>
          </React.Suspense>
      </HashRouter>
    );
}

export default App;
