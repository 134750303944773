import axios from 'axios'

const API_URL = ''

export const USER_NAME_SESSION_ATTRIBUTE_NAME = 'authenticatedUser'
export const USER_NAME_SESSION_TOKEN = 'authenticatedUserToken'

const executeBasicAuthenticationService = (username, password) => {
    return axios.get(`${API_URL}/basicauth`,
        { headers: { authorization: createBasicAuthToken(username, password) } })
}

const executeJwtAuthenticationService = (username, password,fingerPrint) => {
    return axios.post(`${API_URL}/api/user/login`, {
        username,
        password,
        fingerPrint
    })
}

const createBasicAuthToken = (username, password) => {
    return 'Basic ' + window.btoa(username + ":" + password)
}

const registerSuccessfulLogin = (username, password) => {
    //const basicAuthHeader = 'Basic ' +  window.btoa(username + ":" + password)
    sessionStorage.setItem(USER_NAME_SESSION_ATTRIBUTE_NAME, username)
    setupAxiosInterceptors(createBasicAuthToken(username, password))
}

const registerSuccessfulLoginForJwt = (username, token) => {
    sessionStorage.setItem(USER_NAME_SESSION_ATTRIBUTE_NAME, username)
    let jwtToken = createJWTToken(token)
    sessionStorage.setItem(USER_NAME_SESSION_TOKEN, jwtToken)
    //setupAxiosInterceptors(jwtToken)
}

const createJWTToken = (token) => {
    //return 'Bearer ' + token
    return token
}


const logout = () => {
    sessionStorage.removeItem(USER_NAME_SESSION_ATTRIBUTE_NAME);
}

const isUserLoggedIn = () => {
    const user = sessionStorage.getItem(USER_NAME_SESSION_ATTRIBUTE_NAME)
    if (user === null) return false
    return true
}

const getLoggedInUserName = () => {
    const user = sessionStorage.getItem(USER_NAME_SESSION_ATTRIBUTE_NAME)
    if (user === null) return ''
    return user
}

const setupAxiosInterceptors = (token) => {
    axios.interceptors.request.use(
        (config) => {
            if (isUserLoggedIn()) {
                config.headers.authorization = token
            }
            return config
        }
    )
}

const sendPinCode = (username, password,fingerPrint) => {
    return axios.post(`${API_URL}/api/multifactorauthentication/send`, {
        username,
        password,
        fingerPrint
    })
}

const checkPinCode = (username, password,fingerPrint,pinCode) => {
    return axios.post(`${API_URL}/api/multifactorauthentication/check`, {
        username,
        password,
        fingerPrint,
        pinCode
    })
}

const AuthenticationService = {
    executeBasicAuthenticationService: executeBasicAuthenticationService,
    executeJwtAuthenticationService: executeJwtAuthenticationService,
    createBasicAuthToken: createBasicAuthToken,
    registerSuccessfulLogin: registerSuccessfulLogin,
    registerSuccessfulLoginForJwt: registerSuccessfulLoginForJwt,
    createJWTToken: createJWTToken,
    logout: logout,
    isUserLoggedIn: isUserLoggedIn,
    getLoggedInUserName: getLoggedInUserName,
    setupAxiosInterceptors: setupAxiosInterceptors,
    sendPinCode: sendPinCode,
    checkPinCode: checkPinCode
}

export default AuthenticationService