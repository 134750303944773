import { createStore } from 'redux'

const initialState = {
  sidebarShow: 'responsive', 
  showLoading: false,
  showApiError: false,
  currentApiError: {},
  alertModelData: {
    show: false,
    type: "",
    msg: ""
  },
  badgeData: {
    menu: 'チャット',
    color: 'warning',
    text: 0
  },
  badgeDataDp: {
    menu: '調剤予約',
    color: 'warning',
    text: 0
  },
  chatLogData: {
    unreadCount: 0, 
    chatLogList: [], 
    lastGetTime: '',
    aggressiveResults: []
  },
  dpData: {
    unconfirmedCount: 0, 
    dpList: [], 
    dp2Minutes: [],
    lastGetTime: '',
    ringtoneFlg: false
  },
  remindData: {
    remindFlg: false,
    remindInterval: 10
  },
  remindCnt: 0,
  //編集中の予約詳細
  dpDetailCache: {

  },
  chatLogUpdateDuration: 10,
  //sessionに保存すべきデータ
  user: sessionStorage.getItem("user") ? JSON.parse(sessionStorage.getItem("user")) : {},
  authority: sessionStorage.getItem("authority") ? JSON.parse(sessionStorage.getItem("authority")) : {},
  nav: sessionStorage.getItem("nav") ? JSON.parse(sessionStorage.getItem("nav")) : [],
  organization: sessionStorage.getItem("organization") ? JSON.parse(sessionStorage.getItem("organization")) : {},
}

const changeState = (state = initialState, { type, ...rest }) => {
  switch (type) {
    case 'set':
      return {...state, ...rest }
    default:
      return state
  }
}

const store = createStore(changeState)
export default store